import React from 'react';
import { AwardResultPatchMutation } from '../graphql/mutations/AwardResultPatchMutation';
import { AwardResultEditQuery } from '../graphql/query/AwardResultEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardResult.json';
import inputTypeIntro from '../static/introspection/AwardResultPatchInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { AsyncToSyncField } from '../utils/misc';
import { generatePaginatedSelectQuery, graphqlQuery } from '../utils/graphql';
import { generateAsyncQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { EntityEditModal } from '../types/entities';
import { Award, AwardResult } from '../server-types';

export const AwardResultEdit: React.FC<EntityEditModal<AwardResult, Award>> = (
	{ onSubmit, id, originalEntity },
) => (
	<EntityEditFetcher
		id={id}
		onSubmit={onSubmit}
		modal={true}
		fetcher={graphqlQuery(AwardResultEditQuery, { id })}
		mutation={AwardResultPatchMutation}
		entityName={ENTITY.AWARD_RESULT}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={[
			'AwardCategory',
			'Candidacy',
			'legacyCandidacyName',
			'legacyCompanyName',
			'finalPosition',
		]}
		transformer={(data) => {
			const award = originalEntity;
			data.fields = data.fields.map((f) => {
				if (f.name === 'AwardCategory') {
					f = AsyncToSyncField(f, award.categories);
					f.afterChange = (field, value, fields) =>
						fields.map((f) => {
							if (f.name === 'Candidacy') {
								f.value = null;
								if (value) {
									f.disabled = false;
									f.asyncQuery = generateAsyncQuery(
										generatePaginatedSelectQuery(ENTITY.CANDIDACY),
										true,
										(name) => ({
											filter: { name, Award: toRefInput(award) },
										}),
									);
								} else {
									f.disabled = true;
								}
							}
							return f;
						});
				}
				if (f.name == 'finalPosition') {
					if (award.type == "PURCHASING_REQUEST") {
						(f as any).choices = (f as any).choices?.filter((c) => c.value !== 'SECOND' && c.value !== 'THIRD');
					} else {
						(f as any).choices = (f as any).choices?.filter((c) => c.value !== 'REFUSED');
					}
				}
				return f;
			});
			return data;
		}}
	/>
);
