import React from 'react';
import { AwardResultCreateMutation } from '../graphql/mutations/AwardResultCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardResult.json';
import inputTypeIntro from '../static/introspection/AwardResultCreateInput.json';
import { AwardResultEditQuery } from '../graphql/query/AwardResultEditQuery';
import { AsyncToSyncField, generateFakeAsyncQuery, toRequired } from '../utils/misc';
import { ENTITY } from '../utils/entities';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { generatePaginatedSelectQuery } from '../utils/graphql';
import { toRefInput } from '../utils/dataTrasform';

export const AwardResultCreate = (props) => (
	<EntityEditFetcher
		{...props}
		fetcher={
			props.id ? graphqlQuery(AwardResultEditQuery, { id: props.id }) : null
		}
		create={true}
		mutation={AwardResultCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		modal={true}
		entityName={ENTITY.AWARD_RESULT}
		fields={[
			'AwardCategory',
			'Candidacy',
			'legacyCandidacyName',
			'legacyCompanyName',
			'finalPosition',
		]}
		transformer={(data) => {
			const award = props.originalEntity;
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'AwardCategory':
						f = AsyncToSyncField(toRequired(f, true), award.categories);
						f.afterChange = (field, value, fields) =>
							fields.map((f) => {
								if (f.name === 'Candidacy') {
									f.value = null;
									if (value) {
										f.disabled = false;
										f.asyncQuery = generateAsyncQuery(
											generatePaginatedSelectQuery(ENTITY.CANDIDACY),
											true,
											(name) => ({
												filter: { name, Award: toRefInput(award) },
											}),
										);
									} else {
										f.disabled = true;
									}
								}
								return f;
							});
						break;
					case 'Candidacy':
						if (f.type === 'AsyncChoices') {
							f.disabled = true;
							f.asyncQuery = generateFakeAsyncQuery([]);
						}
						break;
					case 'finalPosition':
						if (award.type == "PURCHASING_REQUEST") {
							(f as any).choices = (f as any).choices?.filter((c) => c.value !== 'SECOND' && c.value !== 'THIRD');
						} else {
							(f as any).choices = (f as any).choices?.filter((c) => c.value !== 'REFUSED');
						}
						break;
					default:
						break;
				}
			});
			return data;
		}}
	/>
);
